import React, { useState } from "react"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Background, Parallax } from "react-parallax"
import { Grid, Row, Col } from 'react-bootstrap'
import { graphql, Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import StrataDocumentsModal from "../../documents/strata-documents-modal"
import TitleHeader from "../../../components/title-header"
import AreasWeServeBody from "../../../components/areas-we-serve-body"

const VancouverRentalManagementServices = ({data}) => {
  const [currentForm, setCurrentForm] = useState("");
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  return (
    <Layout>
      <div>
        <SEO title="Company | Korecki Real Estate Services"
             description="We are licensed through the Real Estate Council of B.C., and believe in providing personalized service, ongoing communication and maintaining strong working relationships with our clients"
             keywords="korecki,Real Estate,Strata Management services, Alex Korecki, Vesna Korecki, licensed , Client Property Account System"/>
        <TitleHeader title={data.contentfulResidentialAreasWeServe.title} />
        <AreasWeServeBody data={data.contentfulResidentialAreasWeServe} proposalRequest="rental"/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulResidentialAreasWeServe(title: {eq: "Vancouver"}) {
      title
      description {
        json
      }
      image {
        file {
          url
        }
      }
     
    }
  }`
export default VancouverRentalManagementServices;
